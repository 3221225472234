import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { AxiosRequestConfig } from 'axios'
import type { ErrorType } from 'lib/api/django-axios-instance'
import { djangoAxiosInstance } from 'lib/api/django-axios-instance'

import type { FieldReaderRetrieve, SecondParameter } from './types.fields-mgmt-api'

type RequestOptions = SecondParameter<typeof djangoAxiosInstance>

function v1FieldsList<Schema>(options?: RequestOptions, signal?: AbortSignal): Promise<Schema> {
  return djangoAxiosInstance<Schema>(
    {
      url: `/v1/fields/`,
      method: 'get',
      signal,
    },
    options
  )
}

// Important that an empty array is spread so that we don't end up with `['/v1/fields/',
// undefined]`, which would not allow easy invalidation for ALL the list queries like
// `['/v1/fields/']` does. Awesome article on it:
// https://tkdodo.eu/blog/effective-react-query-keys#structure
export const getV1FieldsListQueryKey = (queryParams?: AxiosRequestConfig['params']): QueryKey => [
  `/v1/fields/`,
  ...(queryParams ? [queryParams] : []),
]

export const useV1FieldsList = <
  TData = Awaited<ReturnType<typeof v1FieldsList>>,
  TError = ErrorType<unknown>,
  TSelect = TData
>(options?: {
  query?: UseQueryOptions<TData, TError, TSelect>
  request?: RequestOptions
}): UseQueryResult<TSelect, TError> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1FieldsListQueryKey(options?.request?.params)
  const queryFn: QueryFunction<TData> = ({ signal }) => v1FieldsList<TData>(requestOptions, signal)

  return useQuery<TData, TError, TSelect>(queryKey, queryFn, queryOptions)
}

/**
 * Readonly endpoint to retrieve a Field
 *
 * @param id Field ID
 * @param options axios request options
 * @param signal AbortSignal instance
 * @returns individual Field
 */
const v1FieldsRetrieve = (
  id: string,
  options?: RequestOptions,
  signal?: AbortSignal
): Promise<FieldReaderRetrieve> => {
  return djangoAxiosInstance<FieldReaderRetrieve>(
    {
      url: `/v1/fields/${id}/`,
      method: 'get',
      signal,
      params: { format: 'geojson', expand: 'onsite_contact' },
    },
    options
  )
}

export const getV1FieldsRetrieveQueryKey = (id: string): QueryKey => {
  return [`/v1/fields/${id}/`]
}

export const useV1FieldsRetrieve = <
  TData = Awaited<ReturnType<typeof v1FieldsRetrieve>>,
  TError = ErrorType<string>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1FieldsRetrieve>>, TError, TData>
    request?: RequestOptions
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getV1FieldsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1FieldsRetrieve>>> = ({ signal }) =>
    v1FieldsRetrieve(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof v1FieldsRetrieve>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  )

  return { queryKey, ...query }
}
