import { useEffect, useMemo, useState } from 'react'
import type { PathMatch } from 'react-router-dom'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'
import type { BatchIdFromRoute } from 'lib/types'

export const RIGHTHAND_ITEM_ELEM_ID = 'righthand-elem'

// CRED: https://tinyurl.com/ye8vptn8
function isTouchEnabled(): boolean {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigator.msMaxTouchPoints > 0 // must be an ancient MS thing?
  )
}

export function useIsTouchEnabled(): boolean {
  return useMemo(() => isTouchEnabled(), [])
}

// Convenient but kinda assumes batchId exists. // TODO: rethink this + fieldId Note that this will
// not work unless the calling component is inside a Route. That's just how this stuff works.
export const useBatchIdFromRouteParams = (): string => {
  const { batchId = '' } = useParams<BatchIdFromRoute>()

  return batchId
}

// Gross hack but it makes the top bar title appear perfectly centered. Should be used on a parent
// wrapper, not the item itself. Can only use it once per page because `id` must be unique.
export function useDynamicElemItemWidth(id = RIGHTHAND_ITEM_ELEM_ID): number | 'auto' {
  const [menuBtnWidth, setMenuBtnWidth] = useState<number | 'auto'>('auto')
  const elem = document.getElementById(id)

  useEffect(() => {
    setMenuBtnWidth(elem?.clientWidth || 'auto')
  }, [elem?.clientWidth])

  return menuBtnWidth
}

// CRED: https://mui.com/material-ui/guides/routing/#tabs
export function useRouteMatch(patterns: readonly string[]): PathMatch<string> | null {
  const { pathname } = useLocation()
  const max = patterns.length

  for (let i = 0; i < max; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)

    if (possibleMatch !== null) return possibleMatch
  }

  return null
}

/**
 * Hook to check if screen width is at least "phablet" (phone <> tablet 'tweener) based on MUI
 * theme.
 *
 * {@link https://mui.com/material-ui/customization/default-theme/ MUI default theme}
 *
 * @returns `true` if the current screen width is at least the number of pixels that mark the start
 * of MUI's `sm` breakpoint.
 */
export function useIsAtLeastPhablet(): boolean {
  const theme = useTheme()
  const isAtLeastPhablet = useMediaQuery(theme.breakpoints.up('sm'))

  return isAtLeastPhablet
}

/**
 * Get URLSearchParams from the current location.
 *
 * @returns URLSearchParams
 */
export function useSearchQuery(): URLSearchParams {
  const { search } = useLocation()

  return new URLSearchParams(search)
}
