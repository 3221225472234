import { useCallback, useReducer } from 'react'
import { toast } from 'react-toastify'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore // it definitely exists 🤷
import toGeoJSON from '@mapbox/togeojson'
import type { UseQueryResult } from '@tanstack/react-query'
import csv from 'csvtojson'
import JSZip from 'jszip'
import type { ErrorType } from 'lib/api/django-axios-instance'
import { LngLatBounds } from 'mapbox-gl'
import type { Openable } from 'shapefile'
import { open as openShp } from 'shapefile'
import { v4 as uuidv4 } from 'uuid'

import { useDrawToolDispatchContext } from 'components/reusable/maps/draw-and-annotate'

import { FILENAME_COLUMN } from './fields-mgmt/column-mapping/config.col-mapping'
import { useV1FieldsList } from './fields-mgmt/queries.fields-mgmt.gets'
import type { RegularJSONfieldAttribs } from './fields-mgmt/types.fields-mgmt-api'
import type { ImportsAction, ImportsState } from './types.fields-mgmt'
import {
  getSpatialRelevanceOfFile,
  hasSameFilenameSansExt,
  isFileOfType,
} from './utils.fields-mgmt'
import { getShouldHideField } from './utils.fields-mgmt-map'

const initialReducerState: ImportsState = {
  latLonColumns: { lat: '', lon: '' },
  modalOpen: false,
  rawFeatures: [],
  requiresLatLonCols: false,
}

const NESTED_GEOM_COLLECTION_ERROR =
  'Attempted to process a GeoJSON feature with a geometry type "GeometryCollection", but no Polygon geometries were found within it. GeometryCollections are only supported if they contain Polygons.'

function importsReducer(state: ImportsState, action: ImportsAction): ImportsState {
  switch (action.type) {
    case 'TOGGLE_IMPORTS_COL_MAP_MODAL':
      return { ...state, modalOpen: action.payload }
    case 'SET_REQUIRES_LAT_LON_COLS':
      return { ...state, requiresLatLonCols: action.payload }
    case 'CLEAR_RAW_IMPORT_FEATURES':
      return { ...state, rawFeatures: [] }
    case 'SET_RAW_IMPORT_FEATURES':
      return {
        ...state,
        rawFeatures: [...state.rawFeatures, ...action.payload],
      }
    default: {
      return state
    }
  }
}

export function useImportsReducer(): [ImportsState, React.Dispatch<ImportsAction>] {
  return useReducer(importsReducer, initialReducerState)
}

/**
 * Sometimes GeoJSON files have extra coordinates that are not lat/lon, e.g. `z` or `m` values. Not
 * sure if it is a problem in _all_ formats, but have seen it consistently in KML/KMZ files.
 *
 * @param geometry GeoJSON geometry to remove extra coordinates from
 * @returns GeoJSON geometry with only lat/lon coordinates
 */
function removeExtraCoordsFromGeometry(geometry: GeoJSON.Geometry): GeoJSON.Geometry {
  if (geometry.type === 'Polygon') {
    return {
      ...geometry,
      coordinates: geometry.coordinates.map((ring) => ring.map((coord) => [coord[0], coord[1]])),
    }
  }

  return geometry
}

/**
 * Munge feature collection into features with filename in properties and a unique `id` (probably)
 *
 * @param featCollection feature collection to be prepped
 * @param filename name of the file to be added to the properties
 * @returns array of features with the filename added to the properties and a unique `id` (probably)
 */
function prepFeatureCollection(
  featCollection: GeoJSON.FeatureCollection,
  filename: string
): GeoJSON.Feature[] {
  return featCollection.features.map((feature) => {
    let geometry: GeoJSON.Geometry

    const isGeometryCollection = feature.geometry.type === 'GeometryCollection'

    // Don't have enough MultiPolygon KML to test with, but we've seen one instance where a
    // MultiPolygon is actually a GeometryCollection with Polygons in it. So, convert it to a
    // MultiPolygon instead of a GeometryCollection.
    if (isGeometryCollection) {
      const geometries = (feature.geometry as GeoJSON.GeometryCollection).geometries.map((geom) =>
        removeExtraCoordsFromGeometry(geom)
      )

      const polygons = geometries.filter((geom) => geom.type === 'Polygon')

      // In theory, couldn't there be infinitely nested GeometryCollections? FMT has been in use for
      // a long time and this GeometryCollection stuff is just now coming up due to the KML/KMZ
      // support, so making a judgement call to only support one level of nesting (Polygons). -JL
      if (!polygons.length) {
        // We're likely grasping at edge-case straws at this point, but at least show a toast and
        // throw an error if we encounter a GeometryCollection with no Polygons in it.
        toast.error(NESTED_GEOM_COLLECTION_ERROR, {
          autoClose: false,
        })

        throw new Error(NESTED_GEOM_COLLECTION_ERROR)
      }

      geometry = {
        type: 'MultiPolygon',
        /* eslint-disable array-bracket-newline */
        coordinates: [
          polygons.map((geom: GeoJSON.Geometry) => (geom as GeoJSON.Polygon).coordinates[0]),
        ],
        /* eslint-enable array-bracket-newline */
      }
    } else {
      geometry = removeExtraCoordsFromGeometry(feature.geometry)
    }

    return {
      ...feature,
      geometry,
      id: uuidv4(),
      properties: {
        ...feature.properties,
        [FILENAME_COLUMN]: filename,
      },
    }
  })
}

/**
 * Parse KML string into GeoJSON FeatureCollection
 *
 * @param text KML source text to parse
 * @returns GeoJSON FeatureCollection
 */
function getGeoJsonFromKmlString(text: string): GeoJSON.FeatureCollection {
  const parser = new DOMParser()
  const kml = parser.parseFromString(text, 'application/xml')
  const featCollection: GeoJSON.FeatureCollection = toGeoJSON.kml(kml)

  return featCollection
}

export function useOnDrop(
  onFilesAccepted: () => void,
  onFilesProcessed: (feats: GeoJSON.Feature[], requiresLatLonCols?: boolean) => void
): (acceptedFiles: File[]) => void {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFilesAccepted()

      const relevantFiles = acceptedFiles.filter(({ name }) => getSpatialRelevanceOfFile(name))

      // TODO: check for mismatch, aka not 1:1 shp:dbf ratio
      relevantFiles.forEach((file: File) => {
        // if (filenameIsNotUnique) return // TODO ???

        const reader = new FileReader()
        const isGeojsonFile = isFileOfType(file.name, 'geojson')
        const isShpFile = isFileOfType(file.name, 'shp')
        const isCsvFile = isFileOfType(file.name, 'csv')
        const isKmlFile = isFileOfType(file.name, 'kml')
        const isKmzFile = isFileOfType(file.name, 'kmz')

        reader.onabort = () => toast.error('File reading aborted')
        reader.onerror = () => toast.error('File reading failed')

        reader.onload = async () => {
          if (isCsvFile) {
            const json = await csv().fromString(reader.result as string)

            const features: GeoJSON.Feature[] = json.map((row) => ({
              type: 'Feature',
              id: uuidv4(),
              // Arbitrary coordinates since we don't know lat/lon cols yet
              geometry: { type: 'Point', coordinates: [0, 0] },
              properties: {
                ...row,
                [FILENAME_COLUMN]: file.name,
              },
            }))

            onFilesProcessed(features, true)

            return
          }

          if (isGeojsonFile) {
            const featCollection: GeoJSON.FeatureCollection = JSON.parse(reader.result as string)
            const features = prepFeatureCollection(featCollection, file.name)

            onFilesProcessed(features, false)

            return
          }

          if (isKmlFile) {
            const stringFromArrayBuffer = new TextDecoder().decode(reader.result as ArrayBuffer)
            const featCollection = getGeoJsonFromKmlString(stringFromArrayBuffer)
            const features = prepFeatureCollection(featCollection, file.name)

            onFilesProcessed(features, false)

            return
          }

          if (isKmzFile) {
            JSZip.loadAsync(file)
              .then((contents) => {
                const kmlKey = Object.keys(contents.files).find((key) => key.endsWith('.kml'))

                if (kmlKey) {
                  return contents.files[kmlKey].async('text')
                }

                return Promise.reject(new Error('No KML file found in KMZ'))
              })
              .then((text) => {
                const featCollection = getGeoJsonFromKmlString(text)
                const features = prepFeatureCollection(featCollection, file.name)

                onFilesProcessed(features, false)
              })
          }

          if (isShpFile) {
            const dbfBuddy = relevantFiles.find((fileToCheck) => {
              return (
                isFileOfType(fileToCheck.name, 'dbf') &&
                hasSameFilenameSansExt(fileToCheck.name, file.name)
              )
            })

            if (!dbfBuddy) return

            const dbfReader = new FileReader()

            dbfReader.onload = () => {
              const shpContents = reader.result as Openable
              const dbfContents = dbfReader.result as Openable
              const allFeats: GeoJSON.Feature[] = []

              openShp(shpContents, dbfContents).then((source) =>
                source
                  .read()
                  .then(function process(result): Promise<void> | undefined {
                    if (result.done) {
                      onFilesProcessed(allFeats, false)

                      return undefined
                    }

                    const asGeoJSON = result.value as GeoJSON.Feature

                    allFeats.push({
                      ...asGeoJSON,
                      id: uuidv4(),
                      properties: {
                        ...asGeoJSON.properties,
                        [FILENAME_COLUMN]: file.name,
                      },
                    })

                    return source.read().then(process)
                  })
                  .catch((e: Error) => {
                    const msg = e instanceof Error ? ` ${e.message}` : ''

                    toast.error(`Import failed.${msg}`)
                  })
              )
            }

            dbfReader.readAsArrayBuffer(dbfBuddy)
          }
        }

        if (isGeojsonFile || isCsvFile) reader.readAsText(file)
        else reader.readAsArrayBuffer(file)
      })
    },
    [onFilesAccepted, onFilesProcessed]
  )

  return onDrop
}

// For the table. Unlike map, which has duplicate manually created features for the purpose of
// labeling. Table still uses the GeoJSON schema though so that we can easily get geometry types,
// etc.
export function useFieldFeatures(
  orgsFilterString = '',
  projectIdFilter = '' // aka engagement number
): UseQueryResult<RegularJSONfieldAttribs[], ErrorType<unknown>> {
  return useV1FieldsList<RegularJSONfieldAttribs[], ErrorType<unknown>, RegularJSONfieldAttribs[]>({
    query: {
      select: (fields) => {
        let unarchivedFields: RegularJSONfieldAttribs[] = []

        const bbox = new LngLatBounds()

        fields.forEach((field) => {
          const { status, lat, lon } = field

          if (!getShouldHideField(status)) {
            bbox.extend([lon, lat])
            unarchivedFields = [...unarchivedFields, field]
          }
        })

        return unarchivedFields
      },
    },
    request: {
      params: {
        format: 'json',
        expand: 'onsite_contact,owned_by',
        hide: 'geohash,extras,geometry',
        orgs: orgsFilterString,
        project_identifiers: projectIdFilter,
      },
    },
  })
}

export function useClearDrawnBoundaryAndStopEditing(): () => void {
  const dispatch = useDrawToolDispatchContext()

  function clear(): void {
    dispatch({ type: 'STOP_EDITING' })
  }

  return clear
}
