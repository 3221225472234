import { createContext, useContext, useReducer } from 'react'

import {
  prepBulkEditImportedFeatAttribs,
  prepImportedFeature,
} from 'components/fields/fields-mgmt/utils.fmt-context'
import type { Action, Dispatch, State } from 'components/fields/types.fields-mgmt'
import { fmtLocalStorage } from 'components/fields/utils.fields-mgmt'

const { getFilteredOrgIdsString, getFilteredProjectId } = fmtLocalStorage

const initialState: State = {
  collnSpatialFiles: [],
  importedFeatures: [],
  filteredOrgIdsString: typeof window !== 'undefined' ? getFilteredOrgIdsString() : null,
  filteredProjectId: typeof window !== 'undefined' ? getFilteredProjectId() : null,
}

const StateContext = createContext<State | undefined>(undefined)
const DispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_ORG_IDS_FILTER':
      return {
        ...state,
        filteredOrgIdsString: action.payload,
      }
    case 'CLEAR_ORG_IDS_FILTER':
      return {
        ...state,
        filteredOrgIdsString: null,
      }
    case 'SET_PROJECT_ID_FILTER':
      return {
        ...state,
        filteredProjectId: action.payload,
      }
    case 'CLEAR_PROJECT_ID_FILTER':
      return {
        ...state,
        filteredProjectId: null,
      }
    case 'CLEAR_IMPORTED_FIELDS':
      return {
        ...state,
        importedFeatures: initialState.importedFeatures,
      }
    case 'DELETE_IMPORTED_FEAT_BY_ID':
      return {
        ...state,
        importedFeatures: state.importedFeatures.filter((f) => {
          return !action.payload.includes(f.id as string)
        }),
      }
    case 'BULK_EDIT_IMPORTED_FEAT_ATTRIBS':
      return {
        ...state,
        importedFeatures: state.importedFeatures.map((f) => {
          return prepBulkEditImportedFeatAttribs(f, action.payload)
        }),
      }
    case 'EDIT_IMPORTED_FEAT_GEOM':
      return {
        ...state,
        importedFeatures: state.importedFeatures.map((feat) => {
          const { id, geometry } = action.payload

          return feat.id === id ? { ...feat, geometry } : feat
        }),
      }
    case 'SET_IMPORTED_FEATURES':
      return {
        ...state,
        importedFeatures: [
          ...state.importedFeatures,
          ...action.payload.features.map((f) => {
            return prepImportedFeature(f, action.payload)
          }),
        ],
      }
    case 'CLEAR_COLLN_SPATIAL_FILES':
      return {
        ...state,
        collnSpatialFiles: initialState.collnSpatialFiles,
      }
    case 'SET_COLLN_SPATIAL_FILES':
      return {
        ...state,
        collnSpatialFiles: [...state.collnSpatialFiles, ...action.payload],
      }
    default: {
      return state
    }
  }
}

export function Providers(props: { children: React.ReactNode }) {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useStateContext(): State {
  const context = useContext(StateContext)

  if (context === undefined) {
    throw new Error('Must use within a Provider!')
  }

  return context
}

function useDispatchContext(): Dispatch {
  const context = useContext(DispatchContext)

  if (context === undefined) {
    throw new Error('Must use within a Provider!')
  }

  return context
}

export { useStateContext, useDispatchContext }
